import { Lightning } from '@lightningjs/sdk';
import { parseMarkdownBold } from '../../../helpers';
import { COLORS, FONT_FACE, FLEX_DIRECTION, ALIGN_ITEMS } from '../../../constants';
const commonStyles = {
    fontSize: 26,
    textColor: COLORS.white6,
};
const regularObject = {
    flexItem: { marginRight: 5 },
    text: {
        ...commonStyles,
        fontFace: FONT_FACE.light,
    },
};
const boldObject = {
    flexItem: { marginRight: 5 },
    text: {
        ...commonStyles,
        fontFace: FONT_FACE.semiBold,
    },
};
const baseObject = (index) => ({
    x: 0,
    y: index * 28,
    flex: { direction: FLEX_DIRECTION.row },
});
export default class Benefit extends Lightning.Component {
    static _template() {
        return {
            h: 80,
            flex: {
                direction: FLEX_DIRECTION.row,
                alignItems: ALIGN_ITEMS.center,
                paddingY: 7,
            },
            Image: {
                flexItem: {
                    marginRight: 13,
                },
                src: this.bindProp('image'),
            },
            Title: { w: 400, h: 66 },
        };
    }
    get title() {
        return this._title;
    }
    set title(title) {
        const titleAsArray = Array.isArray(title) ? title : [title];
        const titleAsString = Array.isArray(title) ? title.join(' ') : title;
        this._title = titleAsString.replace(/\*\*/g, '').replace(/\n/g, '');
        titleAsArray.forEach((t, index) => {
            this.tag('Title').patch({
                [`Title${index}`]: parseMarkdownBold(t, regularObject, boldObject, baseObject(index)),
            });
        });
    }
}
